<template>
  <div class="container page-memberInfo">
    <el-tabs v-model="activeName">
      <el-tab-pane label="基础信息" :name="1">
        <div class="mgb20">
          <div class="bar" style="display: flex" v-if="baseInfo.image">
            <span>头像：</span>
            <el-image
              style="width: 75px; height: 100px"
              :src="baseInfo.image"
              :preview-src-list="[baseInfo.image]"
            >
            </el-image>
          </div>
          <div class="bar">姓名：{{ baseInfo.name }}</div>
          <div class="bar">
            身份：{{ baseInfo.status == 0 ? baseInfo.level_name : "" }}
          </div>
          <div class="bar">性别：{{ baseInfo.sex }}</div>
          <div class="bar">民族：{{ baseInfo.nation }}</div>
          <div class="bar">关系：{{ baseInfo.relation }}</div>
          <div class="bar">身份证：{{ baseInfo.id_card }}</div>
        </div>
        <div class="bar">联系电话：{{ baseInfo.mobile }}</div>
        <div class="bar">国籍：{{ baseInfo.nationality }}</div>
        <div class="bar">出生年月：{{ baseInfo.birthday }}</div>
        <div class="bar">年龄：{{ baseInfo.age }}</div>
        <div class="bar">出生地：{{ baseInfo.birthplace }}</div>
        <div class="bar">居住地址：{{ baseInfo.address }}</div>
      </el-tab-pane>
      <el-tab-pane label="生活信息" :name="2">
        <template v-if="lifeList.length > 0">
          <el-collapse>
            <el-collapse-item
              v-for="(item, index) in lifeList"
              :name="index + 1"
              :key="index"
            >
              <template #title>
                <div class="titleBox flex_align">
                  <div class="f14">{{ item.code }}</div>
                  <div class="f14">健康顾问：{{ item.salesman_name }}</div>
                  <div class="f14">创建时间：{{ item.create_time }}</div>
                </div>
              </template>
              <el-row :gutter="20">
                <el-col :span="10">
                  <div class="bar">吸烟：{{ item.smoke }}</div>
                  <div class="bar">饮酒：{{ item.drink }}</div>
                  <div class="bar">运动：{{ item.sports }}</div>
                  <div class="bar">饮食：{{ item.diet }}</div>
                  <div class="bar">睡眠：{{ item.sleep }}</div>
                </el-col>
                <el-col :span="10">
                  <div class="bar">小便：{{ item.pee }}</div>
                  <div class="bar">大便：{{ item.excrement }}</div>
                  <div class="bar">女性月经：{{ item.menstruation }}</div>
                  <div class="bar">女性生育：{{ item.give_birth }}</div>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </template>
        <template v-else>
          <el-empty description="暂无数据"></el-empty>
        </template>
      </el-tab-pane>
      <el-tab-pane label="健康信息" :name="3">
        <template v-if="healthyList.length > 0">
          <el-collapse>
            <el-collapse-item
              v-for="(item, index) in healthyList"
              :name="index + 1"
              :key="index"
            >
              <template #title>
                <div class="titleBox flex">
                  <div class="flex_align">
                    <div class="f14">{{ item.code }}</div>
                    <div class="f14">专家：{{ item.medic_name }}</div>
                    <div class="f14">创建时间：{{ item.create_time }}</div>
                  </div>
                  <div v-if="getUserId == item.medic_id && item.status === 0">
                    <el-button type="primary" @click="healthyEdit(item.id)"
                      >编辑</el-button
                    >
                  </div>
                </div>
              </template>
              <el-row :gutter="20" class="topBorder">
                <el-col :span="12">
                  <h3 class="title">体格信息</h3>
                  <div class="flex_align">
                    <div class="bar">
                      身高：{{ item.height ? item.height + "cm" : "" }}
                    </div>
                    <div class="bar">
                      体重：{{ item.weight ? item.weight + "kg" : "" }}
                    </div>
                    <div class="bar">
                      血压：{{
                        item.blood_pressure ? item.blood_pressure + "mmHg" : ""
                      }}
                    </div>
                    <div class="bar">
                      脉搏：{{ item.sphygmus ? item.sphygmus + "次/分" : "" }}
                    </div>
                  </div>
                  <h3 class="title">诊疗单</h3>
                  <el-table
                    :data="item.diagnosis"
                    style="width: 100%; margin-bottom: 20px"
                  >
                    <el-table-column type="index" width="50" label="序号">
                    </el-table-column>
                    <el-table-column prop="diagnosis_name" label="诊疗项目">
                    </el-table-column>
                    <el-table-column prop="specification" label="规格型号">
                    </el-table-column>
                    <el-table-column prop="unit" label="单位">
                    </el-table-column>
                    <el-table-column
                      prop="count"
                      label="数量"
                    ></el-table-column>
                    <el-table-column prop="msg" label="备注"> </el-table-column>
                  </el-table>
                  <div
                    class="markup"
                    v-if="item.diagnosis_msgs"
                    style="display: flex; align-items: center"
                  >
                    <!-- <div class="markup"> -->
                    <p class="markup-title">诊疗备注：</p>
                    <p class="markup-content">{{ item.diagnosis_msgs }}</p>
                  </div>
                  <h3 class="title">药品单</h3>
                  <el-table
                    :data="item.drug"
                    style="width: 100%; margin-bottom: 20px"
                  >
                    <el-table-column type="index" width="50" label="序号">
                    </el-table-column>
                    <el-table-column prop="medicine_name" label="药品名称">
                    </el-table-column>
                    <el-table-column prop="specification" label="规格型号">
                    </el-table-column>
                    <el-table-column prop="unit" label="单位">
                    </el-table-column>
                    <el-table-column
                      prop="count"
                      label="数量"
                    ></el-table-column>
                    <el-table-column prop="msg" label="备注"> </el-table-column>
                  </el-table>
                  <div
                    class="markup"
                    v-if="item.drug_msgs"
                    style="display: flex; align-items: center"
                  >
                    <!-- <div class="markup"> -->
                    <p class="markup-title">药品备注：</p>
                    <p class="markup-content">{{ item.drug_msgs }}</p>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="flex">
                    <h3 class="title">病历信息</h3>
                  </div>
                  <div class="mgb20">
                    <div class="bar">看诊项目：{{ item.disease_name }}</div>
                    <div class="bar">主诉：{{ item.appeal }}</div>
                    <div class="bar">现病史：{{ item.now }}</div>
                    <div class="bar">既往史：{{ item.before }}</div>
                    <div class="bar">体格检查：{{ item.physique }}</div>
                    <div class="bar">诊断：{{ item.diagnose }}</div>
                    <div class="bar">治疗方案：{{ item.treatment }}</div>
                    <div class="bar">远程会诊意见：{{ item.remote_views }}</div>
                    <div class="bar">
                      诊后注意事项：{{ item.announcements }}
                    </div>
                    <div class="bar">备注：{{ item.msg }}</div>
                  </div>
                  <h3 class="title" style="margin-bottom: 10px">评分表数据</h3>
                  <div
                    class="bar"
                    style="margin-bottom: 10px"
                    v-for="(items, index2) in item.score"
                    :key="index2"
                  >
                    <el-button @click="seeScoring(items)" type="text">
                      {{ items.name }}({{ items.date }})</el-button
                    >
                  </div>
                  <div class="bar">
                    新录评分表：
                    <el-select
                      v-model="value"
                      placeholder="请选择"
                      clearable
                      @change="addScoreList(value, item.id)"
                    >
                      <el-option
                        v-for="item in scoreList"
                        :key="item.number"
                        :label="item.name"
                        :value="item.number"
                      ></el-option>
                    </el-select>
                  </div>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </template>
        <template v-else>
          <el-empty description="暂无数据"></el-empty>
        </template>
      </el-tab-pane>
      <el-tab-pane label="报告信息" :name="4">
        <el-button
          type="primary"
          icon="el-icon-plus"
          style="margin-bottom: 15px"
          @click="addReport(baseInfo.id, 1)"
          >新增批次报告</el-button
        >
        <template v-if="reportList.length > 0">
          <el-collapse accordion="false">
            <el-collapse-item
              v-for="(item, index) in reportList"
              :name="index + 1"
              :key="index"
            >
              <template #title>
                <div class="titleBox flex_align">
                  <div class="f14">{{ item.code }}</div>
                  <div class="f14">
                    {{ item.type_name }}：{{ item.salesman_name }}
                  </div>
                  <div class="f14">创建时间：{{ item.create_time }}</div>
                  <div class="Max2" @click="setData($event, item.id)">
                    <span>编辑</span>
                  </div>
                  <div class="Max" @click="removeData($event, item.id)">
                    <span>删除</span>
                  </div>
                </div>
              </template>
              <el-row :gutter="20">
                <template v-if="item.image.length > 0">
                  <el-col :span="6" v-for="items in item.image" :key="items.id">
                    <div class="list4">
                      <el-image
                        style="width: 200px; height: 200px"
                        :preview-src-list="[items.image]"
                        :src="items.image"
                        fit="cover"
                      >
                      </el-image>
                      <div class="bar">{{ items.title }}</div>
                      <div class="bar">检查日期：{{ items.check_time }}</div>
                    </div>
                  </el-col>
                </template>
                <template v-if="item.video.length > 0">
                  <el-col :span="6" v-for="items in item.video" :key="items.id">
                    <div class="list4">
                      <video
                        style="width: 200px; height: 200px"
                        controls="controls"
                        :src="items.video"
                      ></video>
                      <div class="bar">{{ items.title }}</div>
                      <div class="bar">检查日期：{{ items.check_time }}</div>
                    </div>
                  </el-col>
                </template>
                <template v-if="item.file.length > 0">
                  <el-col :span="6" v-for="items in item.file" :key="items.id">
                    <div class="list4">
                      <div
                        style="
                          width: 200px;
                          height: 200px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                        @click="fileClick(items.file)"
                      >
                        <el-image
                          :src="require('../../assets/img/pdf.png')"
                          fit="cover"
                        >
                        </el-image>
                      </div>
                      <div class="bar">{{ items.title }}</div>
                      <div class="bar">检查日期：{{ items.check_time }}</div>
                    </div>
                  </el-col>
                </template>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </template>
        <template v-else>
          <el-empty description="暂无数据"></el-empty>
        </template>
      </el-tab-pane>
      <el-tab-pane label="治疗日志" :name="5">
        <el-button
          type="primary"
          icon="el-icon-plus"
          style="margin-bottom: 15px"
          @click="addReport(baseInfo.id, 2)"
          >新增治疗日志</el-button
        >
        <template v-if="treatmentList.length > 0">
          <el-collapse>
            <el-collapse-item
              v-for="(item, index) in treatmentList"
              :name="index + 1"
              :key="index"
            >
              <template #title>
                <div class="titleBox flex_align">
                  <div class="f14">{{ item.code }}</div>
                  <div class="f14">{{ item.type_name }}：{{ item.names }}</div>
                  <div class="f14">日期：{{ item.date }}</div>
                  <div class="f14">创建时间：{{ item.create_time }}</div>
                  <div
                    class="Max2"
                    @click="setLogListData($event, item.id), (treatment = true)"
                  >
                    <span>编辑</span>
                  </div>
                  <div class="Max" @click="removeLogListData($event, item.id)">
                    <span>删除</span>
                  </div>
                </div>
              </template>
              <div class="bar">{{ item.msg }}</div>
              <el-row :gutter="20">
                <template v-if="item.images.length > 0">
                  <el-col
                    :span="6"
                    v-for="items in item.images"
                    :key="items.id"
                  >
                    <div class="list4">
                      <el-image
                        style="width: 200px; height: 200px"
                        :preview-src-list="item.images"
                        :src="items"
                        fit="cover"
                      ></el-image>
                    </div>
                  </el-col>
                </template>
                <template v-if="item.video.length > 0">
                  <el-col :span="6" v-for="items in item.video" :key="items.id">
                    <div class="list4">
                      <video
                        style="width: 200px; height: 200px"
                        controls="controls"
                        :src="items"
                      ></video>
                    </div>
                  </el-col>
                </template>
                <template v-if="item.file.length > 0">
                  <el-col :span="6" v-for="items in item.file" :key="items.id">
                    <div class="list4">
                      <div
                        style="
                          width: 200px;
                          height: 200px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                        @click="fileClick(items)"
                      >
                        <el-image
                          :src="require('../../assets/img/pdf.png')"
                          fit="cover"
                        >
                        </el-image>
                      </div>
                    </div>
                  </el-col>
                </template>
              </el-row>
            </el-collapse-item>
          </el-collapse>
          <div class="pagination">
            <el-pagination
              background
              layout="total, prev, pager, next, sizes"
              :current-page="query.page"
              :page-size="query.limt"
              :page-sizes="[10, 20, 50]"
              :total="pageTotal"
              @current-change="handlePageChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </div>
        </template>
        <template v-else>
          <el-empty description="暂无数据"></el-empty>
        </template>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      title="编辑治疗"
      v-model="treatment"
      width="1000px"
      :before-close="treatmentClose"
    >
      <el-form :model="form2" label-width="100px" :rules="rules" ref="form">
        <el-row :gutter="20" v-for="(item, index) in form2" :key="index">
          <el-col :span="10">
            <el-form-item
              label="治疗日期"
              :prop="index + '.check_time'"
              :rules="rules.check_time"
            >
              <el-date-picker
                v-model="item.check_time"
                type="date"
                @blur="checkTimeBind(index)"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              class="textwidth"
              label="治疗日志"
              :prop="index + '.title'"
              :rules="rules.title"
            >
              <el-input
                type="textarea"
                v-model="item.title"
                :autosize="{ minRows: 5, maxRows: 8 }"
                placeholder="请输入治疗日志"
                style="width: 300px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="治疗文件">
              <el-upload
                class="upload_btnbox"
                list-type="picture"
                :show-file-list="true"
                :file-list="form2[0].showItems"
                :data="{ name: index }"
                :action="httpUrl + 'common/upload'"
                :on-preview="handlePreview"
                :on-remove="handleTreamentRemove"
                :on-success="handleTreamentSuccess"
                :on-progress="handleProgress"
                multiple
                :limit="20"
                :before-upload="beforeAvatarUpload"
                :on-exceed="handleExceed"
              >
                <!-- <template #file="{ file }">
                  <div
                    class="file-preview"
                    @click="handlePreview(file)"
                    style="cursor: pointer"
                  >
                    <div class="file-pic" v-if="file.type == 'image'">
                      <img :src="file.url" style="height: 70px; width: 70px" />
                    </div>
                    <div
                      class="file-pic file-type"
                      v-else-if="file.type == 'file'"
                    >
                      <el-image
                        :src="require('../../assets/img/pdf.png')"
                        fit="cover"
                      ></el-image>
                    </div>
                    <div
                      class="file-pic file-type"
                      v-else-if="file.type == 'video'"
                      style="width: unset; height: unset; margin: 0 auto"
                    >
                      <video
                        style="width: 200px; height: 200px"
                        controls="controls"
                        :src="file.url"
                      ></video>
                    </div>
                    <div class="file-name" v-if="file.type !== 'video'">
                      {{ file.name }}
                    </div>
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click.stop="handleTreamentRemove(file)"
                      style="display: unset; top: 0; right: 0"
                    >
                      <i class="el-icon-close"></i>
                    </span>
                  </div>
                </template> -->
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
              <!-- 							<div class="file-list">
								<p v-for="form2[0].showItems">文件列表</p>
							</div> -->

              <!--              <el-dialog v-model="dialogVisible" :width="600">
                <img
                  v-if="dialogImageUrl"
                  style="width:100%"
                  :src="dialogImageUrl"
                  alt=""
                />
                <video
                  v-if="dialogVideoUrl"
                  style="width: 100%;"
                  controls="controls"
                  :src="dialogVideoUrl"
                ></video>
              </el-dialog> -->
              <!--              <div class="imgflex">
                <img
                  class="imgsize"
                  v-if="dialogImageUrl"
                  :src="dialogImageUrl"
                  alt=""
                />
              </div> -->
              <!--              <div>
                <video
                  v-if="dialogVideoUrl"
                  class="imgsize"
                  controls="controls"
                  :src="dialogVideoUrl"
                ></video>
              </div> -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            icon="el-icon-folder-checked"
            @click="submitInfoEditLog"
            >保存</el-button
          >
        </span>
      </template>
    </el-dialog>
    <el-dialog
      title="编辑报告"
      v-model="report"
      width="900px"
      :before-close="handleEditReport"
    >
      <el-form :model="form" label-width="100px" :rules="rules" ref="form">
        <el-row :gutter="20" v-for="(item, index) in form" :key="index">
          <el-col :span="10">
            <el-form-item
              label="检查日期"
              :prop="index + '.check_time'"
              :rules="rules.check_time"
            >
              <el-date-picker
                v-model="item.check_time"
                type="date"
                @blur="checkTimeBind(index)"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              label="报告名称"
              :prop="index + '.title'"
              :rules="rules.title"
            >
              <el-input
                class="reportInp"
                v-model="item.title"
                placeholder="请输入报告名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="报告文件">
              <el-upload
                :data="{ name: index }"
                :file-list="item.showImages"
                class="upload_btnbox"
                list-type="picture"
                :action="httpUrl + 'common/upload'"
                :on-preview="handlePreview"
                :on-success="handleAvatarSuccess"
                :on-progress="handleProgress"
                multiple
                :limit="20"
                :before-upload="beforeAvatarUpload"
                :on-exceed="handleExceed"
              >
                <template #file="{ file }">
                  <div
                    class="file-preview"
                    @click="handlePreview(file)"
                    style="cursor: pointer"
                  >
                    <div class="file-pic" v-if="file.type == 'image'">
                      <img :src="file.url" style="height: 70px; width: 70px" />
                    </div>
                    <div
                      class="file-pic file-type"
                      v-else-if="file.type == 'file'"
                    >
                      <el-image
                        :src="require('../../assets/img/pdf.png')"
                        fit="cover"
                      ></el-image>
                    </div>
                    <div
                      class="file-pic file-type"
                      v-else-if="file.type == 'video'"
                      style="width: unset; height: unset; margin: 0 auto"
                    >
                      <video
                        style="width: 200px; height: 200px"
                        controls="controls"
                        :src="file.url"
                      ></video>
                    </div>
                    <div class="file-name" v-if="file.type !== 'video'">
                      {{ file.name }}
                    </div>
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click.stop="handleRemove(file)"
                      style="display: unset; top: 0; right: 0"
                    >
                      <i class="el-icon-close"></i>
                    </span>
                  </div>
                </template>
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
              <!-- <el-button size="small" @click="updateTxt(index)" type="primary">修改文件</el-button> -->
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button
              type="danger"
              @click="removeBtn(index)"
              v-if="index > 0"
              icon="el-icon-remove"
              >删除
            </el-button>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="warning" @click="addBtn">添加报告</el-button>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            icon="el-icon-folder-checked"
            @click="submitinfoEditReport"
            >保存</el-button
          >
        </span>
      </template>
    </el-dialog>
    <el-dialog title="修改文件" v-model="isUpdate">
      <el-row :gutter="20">
        <template v-if="updateData.image.length > 0">
          <el-col :span="6" v-for="items in updateData.image" :key="items.id">
            <div class="list4">
              <el-image
                style="width: 200px; height: 200px"
                :preview-src-list="[items.image]"
                :src="items"
                fit="cover"
              ></el-image>
              <div class="bar">{{ updateData.title }}</div>
              <div class="bar">检查日期：{{ updateData.check_time }}</div>
              <el-button type="danger" icon="el-icon-delete" circle></el-button>
            </div>
          </el-col>
        </template>
        <template v-if="updateData.video.length > 0">
          <el-col :span="6" v-for="item in updateData.video" :key="item.id">
            <div class="list4">
              <video
                style="width: 200px; height: 200px"
                controls="controls"
                :src="item"
              ></video>
              <div class="bar">{{ updateData.title }}</div>
              <div class="bar">检查日期：{{ updateData.check_time }}</div>
              <el-button type="danger" icon="el-icon-delete" circle></el-button>
            </div>
          </el-col>
        </template>
        <template v-if="updateData.file.length > 0">
          <el-col :span="6" v-for="items in updateData.file" :key="items.id">
            <div class="list4">
              <div
                style="
                  width: 200px;
                  height: 200px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
                @click="fileClick(items)"
              >
                <el-image
                  :src="require('../../assets/img/pdf.png')"
                  fit="cover"
                ></el-image>
              </div>
              <div class="bar">{{ updateData.title }}</div>
              <div class="bar">检查日期：{{ updateData.check_time }}</div>
              <el-button type="danger" icon="el-icon-delete" circle></el-button>
            </div>
          </el-col>
        </template>
      </el-row>
    </el-dialog>

    <el-dialog v-model="dialogVisible" :width="600">
      <img
        v-if="dialogImageUrl"
        style="width: 100%"
        :src="dialogImageUrl"
        alt=""
      />
      <video
        v-if="dialogVideoUrl"
        style="width: 100%"
        controls="controls"
        :src="dialogVideoUrl"
      ></video>
    </el-dialog>
  </div>
</template>

<script>
import {
  basicInformation,
  healthInformation,
  reportData,
  getLife,
  logList,
  delLog,
  infoGetLog,
  infoDelReport,
  infoEditLog,
  infoEditReport,
  infoGetLogs,
} from "../../api/index";
import globalData from "../../utils/data";
export default {
  name: "memberInfo",
  data() {
    return {
      fileStartCount: 0,
      changeCount: 0,

      reportFilelist: [],

      query: {
        page: 1,
        limt: 10,
        id: "",
      },
      uploadloading: false,
      showUploading: false,
      pageTotal: 0, //总条数
      baseInfo: {}, //基本信息数据
      lifeList: [], //生活信息数据
      healthyList: [], //健康信息数据
      reportList: [], //报告信息数据
      treatmentList: [], //治疗日志
      scoreList: [
        //评分表数据
        {
          name: "脑卒中",
          number: 1,
          total_points: 0,
          str: {},
        },
        {
          name: "老年性痴呆",
          number: 2,
          total_points: 0,
          str: {},
        },
        {
          name: "脑瘫",
          number: 3,
          total_points: 0,
          str: {},
        },
        {
          name: "帕金森氏病",
          number: 4,
          total_points: 0,
          str: {},
        },
      ],
      activeName: 1, //选项卡切换
      id: "",
      value: "",
      treatment: false,
      report: false,
      isUpdate: false,
      updateData: null, //修改数据显示
      userId: "",
      httpUrl: globalData.httpUrl,
      dialogImageUrl: "",
      dialogVideoUrl: "",
      infoEditReport: "",
      dialogVisible: false,
      form: [
        {
          title: "",
          check_time: "",
          showImages: [],
          image: "",
          video: "",
          file: "",
          id: "",
        },
      ],
      form2: [
        {
          title: "",
          check_time: "",
          image: "",
          video: "",
          file: "",
          id: "",
        },
      ],
      rules: {
        title: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        check_time: [
          {
            required: true,
            message: "请选择日期",
            trigger: "blur",
          },
        ],
      },
      fileList: [],
    };
  },
  created() {},
  activated() {
    let id = this.$route.query.id;
    this.userId = this.$route.query.id;
    this.value = "";
    this.id = id;
    this.query.id = id;
    this.getBase(id);
    this.getHealthy(id);
    this.getReportData(id);
    this.onGetLife(id);
    this.getLogList();
  },
  computed: {
    getUserId() {
      //获取管理员id
      return this.$store.state.userInfo.id;
    },
  },
  methods: {
    stopAndWait() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 300);
      });
    },

    //编辑修改文件
    handleProgress() {
      this.uploadloading = true;
      this.showUploading = true;
      console.log(this.uploadloading);
    },
    updateTxt(index) {
      this.isUpdate = true;
      this.updateData = this.form[index];
    },
    addBtn() {
      this.form.push({
        title: "",
        check_time: "",
        image: "",
        video: "",
        file: "",
        showImages: [],
      });
    },
    submitInfoEditLog() {
      let temp = this.form2[0];
      console.log(temp);
      let image = [
        ...temp.image.split(";"),
        ...temp.video.split(";"),
        ...temp.file.split(";"),
      ];
      console.log(image);
      image = image.filter((item) => {
        return item;
      });
      console.log(image);
      let data = {
        id: temp.id,
        date: temp.check_time,
        msg: temp.title,
        // image: image.join(";"),
        image: this.fileList.join(";"),
        // image:
      };
      console.log(data);
      infoEditLog({
        ...data,
      }).then((res) => {
        console.log(res);
        this.treatment = false;
        this.getLogList();
      });
    },
    removeBtn(index) {
      this.form.splice(index, 1);
    },
    setLogListData($event, id) {
      this.fileList = [];
      $event.stopPropagation();
      this.treatment = true;
      infoGetLog({
        id,
      }).then((res) => {
        console.log(res);
        this.form2[0].title = res.msg;
        this.form2[0].check_time = res.date;
        this.form2[0].image = res.images.join(";");
        this.fileList = res.images;
        this.dialogImageUrl = res.images[0];
        this.dialogVideoUrl = res.video[0];
        this.form2[0].file = res.file.join(";");
        this.form2[0].video = res.video.join(";");
        this.form2[0].id = res.id;
        this.form2[0].showItems = [
          ...res.images,
          ...res.file,
          ...res.video,
        ].map((item) => {
          return {
            name: item,
            url: item,
            ifGetFromBackEnd: true,
            type: this.filterFileType(item),
          };
        });
        console.log(this.form2[0]);
      });
    },
    removeLogListData($event, id) {
      $event.stopPropagation();
      delLog({
        id,
      }).then(() => {
        this.getLogList();
      });
    },

    setData($event, id) {
      this.infoEditReport = id;
      // let giveName = this.filterFileType
      $event.stopPropagation();
      this.report = true;
      infoGetLogs({
        id,
      }).then((res) => {
        let result = res.map((I, index) => {
          // console.log(index)
          let item = Object.assign({}, I);
          // console.log(item)
          let showImages = [];
          item.image.forEach((img) => {
            // let type =
            // console.log('set img', img)
            // showImages.push({name: img, url: img, ifGetFromBackEnd: true, qigeming: img, index, })
            showImages.push({
              name: img,
              url: img,
              index,
              type: "image",
              ifGetFromBackEnd: true,
            });
          });
          item.file.forEach((file) => {
            // console.log('set file', file)
            // showImages.push({name: file, url: file, ifGetFromBackEnd: true, qigeming: file, index, })
            showImages.push({
              name: file,
              url: file,
              index,
              type: "file",
              ifGetFromBackEnd: true,
            });
          });
          item.video.forEach((video) => {
            // console.log('set video', video)
            // showImages.push({name: video, url: video, ifGetFromBackEnd: true, qigeming: video, index, })
            showImages.push({
              name: video,
              url: video,
              index,
              type: "video",
              ifGetFromBackEnd: true,
            });
          });
          // console.log(item)
          // console.log(showImages)
          item.showImages = showImages;
          item.file = item.file.join(";");
          item.image = item.image.join(";");
          item.video = item.video.join(";");
          // // console.log(item)
          return item;
        });

        this.form = result;
      });
    },

    filterFileType(filename) {
      let suffix = filename.split(".").pop().toUpperCase();
      let fileType;
      if (
        suffix === "JPG" ||
        suffix === "JPEG" ||
        suffix === "PNG" ||
        suffix === "GIF"
      ) {
        fileType = "image";
      } else if (
        suffix === "AVI" ||
        suffix === "MOV" ||
        suffix === "MP4" ||
        suffix === "3GP" ||
        suffix === "FLV" ||
        suffix === "RMVB" ||
        suffix === "RM"
      ) {
        fileType = "video";
      } else {
        fileType = "file";
      }
      return fileType;
    },

    submitinfoEditReport() {
      console.log(this.form);
      this.form.forEach((item) => {
        if (!item.image.length) {
          item.image = "";
        }
        if (!item.video.length) {
          item.video = "";
          console.log(item.video);
        }
        if (!item.file.length) {
          item.file = "";
        }
      });
      let arr = this.form;
      console.log(arr);
      let id = this.infoEditReport;
      infoEditReport({
        arr,
        id,
      }).then(() => {
        this.report = false;
        let id = this.userId;
        this.getReportData(id);
      });
    },
    removeData($event, id) {
      $event.stopPropagation();
      infoDelReport({
        id,
      }).then(() => {
        let id = this.userId;
        this.getReportData(id);
      });
    },
    // 获取基础信息
    getBase(id) {
      basicInformation({
        id,
      }).then((res) => {
        this.baseInfo = res;
      });
    },

    // 获取生活信息
    onGetLife(id) {
      getLife({
        id,
      }).then((res) => {
        this.lifeList = res;
      });
    },

    // 获取健康信息
    getHealthy(id) {
      healthInformation({
        id,
      }).then((res) => {
        console.log("健康档案信息", res);
        this.healthyList = res;
      });
    },

    // 打开pdf文件
    fileClick(http) {
      console.log(http);
      window.open(http, "_blank");
    },

    // 获取报告信息
    getReportData(id) {
      reportData({
        id,
      }).then((res) => {
        // res.map(item=>{
        //     let arr = []
        //     item.log.map(items=>{
        //         arr.push(items.image)
        //         items.srcList = arr
        //     })
        // })

        this.reportList = res;
        console.log("报告信息", this.reportList);
      });
    },

    // 获取治疗日志
    getLogList() {
      logList(this.query).then((res) => {
        console.log(res);
        this.treatmentList = res.list;
        this.pageTotal = res.total;
      });
    },

    // 分页导航
    handlePageChange(val) {
      this.query.page = val;
      this.getLogList();
    },
    handleSizeChange(val) {
      this.query.page = 1;
      this.query.limt = val;
      this.getLogList();
    },

    // 编辑诊疗单
    healthyEdit(id) {
      this.$router.push({
        path: "/newEdit",
        query: {
          id: id,
          types: 2,
        },
      });
    },

    // 查看评分表
    seeScoring(data) {
      this.$router.push({
        path: "/newScoring",
        query: {
          data: JSON.stringify(data),
        },
      });
    },

    // 新录评分表
    addScoreList(val, id) {
      if (val) {
        this.$router.push({
          path: "/newScoring",
          query: {
            data: JSON.stringify(this.scoreList[val - 1]),
            id: id,
          },
        });
      }
    },

    // 新增报告
    addReport(id, status) {
      this.$router.push({
        path: "/memberAdd",
        query: {
          id,
          status,
        },
      });
    },

    checkTimeBind(index) {
      let date = this.form[index].check_time;

      let date2 = this.form2[index] && this.form2[index].check_time;
      console.log(date);
      if (date) {
        if (date.getFullYear) {
          const d =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate();
          this.form[index].check_time = d;
        }
      } else {
        console.log(date);
        // this.form[index].check_time = ''
      }
      if (date2) {
        if (date2.getFullYear) {
          const d =
            date2.getFullYear() +
            "-" +
            (date2.getMonth() + 1) +
            "-" +
            date2.getDate();
          this.form2[index].check_time = d;
        }
      } else {
        console.log(date2);
        // this.form[index].check_time = ''
      }
    },

    handleRemove(fileDeleted) {
      //文件列表移除文件时的钩子
      console.log(fileDeleted);
      let idx;
      let nameOfDeleted;
      if (fileDeleted.ifGetFromBackEnd) {
        idx = fileDeleted.index;
        nameOfDeleted = fileDeleted.name;
      } else {
        idx = fileDeleted.response.data.name;
        nameOfDeleted = fileDeleted.response.data.img_name;
      }
      let fileList = this.form[idx].showImages;
      let indexDeleted = null;
      this.form[idx].image = "";
      this.form[idx].video = "";
      this.form[idx].file = "";
      if (fileList.length > 0) {
        fileList.forEach((item, index) => {
          let suffix;
          let filename;
          if (item.ifGetFromBackEnd) {
            console.log(item);
            suffix = item.name.split(".").pop().toUpperCase();
            filename = item.name;
          } else {
            suffix = item.response.data.suffix.toUpperCase();
            filename = item.response.data.img_name;
          }
          if (filename === nameOfDeleted) {
            indexDeleted = index;
            return;
          }

          if (
            suffix === "JPG" ||
            suffix === "JPEG" ||
            suffix === "PNG" ||
            suffix === "GIF"
          ) {
            this.form[idx].image += filename + ";";
          } else if (
            suffix === "AVI" ||
            suffix === "MOV" ||
            suffix === "MP4" ||
            suffix === "3GP" ||
            suffix === "FLV" ||
            suffix === "RMVB" ||
            suffix === "RM"
          ) {
            this.form[idx].video += filename + ";";
          } else {
            this.form[idx].file += filename + ";";
          }
        });
        if (this.form[idx].image.length > 0) {
          this.form[idx].image = this.form[idx].image.substr(
            0,
            this.form[idx].image.length - 1
          );
        }
        if (this.form[idx].video.length > 0) {
          this.form[idx].video = this.form[idx].video.substr(
            0,
            this.form[idx].video.length - 1
          );
        }
        if (this.form[idx].file.length > 0) {
          this.form[idx].file = this.form[idx].file.substr(
            0,
            this.form[idx].file.length - 1
          );
        }
      }
      this.form[idx].showImages.splice(indexDeleted, 1);
    },
    handleTreamentRemove(fileDeleted) {
      console.log(fileDeleted.name);
      let i = this.fileList.findIndex((v) => v == fileDeleted.name);
      console.log(i);
      this.fileList.splice(i, 1);
      console.log(this.form2[0]);
      console.log(this.fileList);
      //  this.$forceUpdate();
    },
    handleTreamentRemove1(fileDeleted) {
      //文件列表移除文件时的钩子
      console.log(fileDeleted);
      let idx;
      let nameOfDeleted;
      let fileList = this.form2[0].showItems;
      if (fileDeleted.ifGetFromBackEnd) {
        idx = 0;
        nameOfDeleted = fileDeleted.name;
      } else {
        idx = fileDeleted.response.data.name;
        nameOfDeleted = fileDeleted.response.data.img_name;
      }
      this.form2[idx].image = "";
      this.form2[idx].video = "";
      this.form2[idx].file = "";
      let indexDeleted = null;
      console.log(indexDeleted);
      console.log(fileList);
      if (fileList.length > 0) {
        fileList.forEach((item, index) => {
          let suffix;
          let filename;
          if (item.ifGetFromBackEnd) {
            suffix = item.name.split(".").pop().toUpperCase();
            filename = item.name;
          } else {
            suffix = item.response.data.suffix.toUpperCase();
            filename = item.response.data.img_name;
          }

          if (filename === nameOfDeleted) {
            indexDeleted = index;
            return;
          }

          if (
            suffix === "JPG" ||
            suffix === "JPEG" ||
            suffix === "PNG" ||
            suffix === "GIF"
          ) {
            this.form2[idx].image += filename + ";";
          } else if (
            suffix === "AVI" ||
            suffix === "MOV" ||
            suffix === "MP4" ||
            suffix === "3GP" ||
            suffix === "FLV" ||
            suffix === "RMVB" ||
            suffix === "RM"
          ) {
            this.form2[idx].video += filename + ";";
          } else {
            this.form2[idx].file += filename + ";";
          }
        });
        if (this.form2[idx].image.length > 0) {
          this.form2[idx].image = this.form2[idx].image.substr(
            0,
            this.form2[idx].image.length - 1
          );
        }
        if (this.form2[idx].video.length > 0) {
          this.form2[idx].video = this.form2[idx].video.substr(
            0,
            this.form2[idx].video.length - 1
          );
        }
        if (this.form2[idx].file.length > 0) {
          this.form2[idx].file = this.form2[idx].file.substr(
            0,
            this.form2[idx].file.length - 1
          );
        }
      }
      console.log(indexDeleted);
      this.form2[idx].showItems.splice(indexDeleted, 1);
    },

    handlePreview(file) {
      //点击文件列表中已上传的文件时的钩子
      console.log(file);
      this.dialogImageUrl = "";
      this.dialogVideoUrl = "";
      console.log(file);
      let suffix;
      let filename;
      if (file.ifGetFromBackEnd) {
        suffix = file.name.split(".").pop().toUpperCase();
        filename = file.name;
      } else {
        suffix = file.response.data.suffix.toUpperCase();
        filename = file.response.data.img_name;
      }
      if (
        suffix === "JPG" ||
        suffix === "JPEG" ||
        suffix === "PNG" ||
        suffix === "GIF"
      ) {
        this.dialogImageUrl = filename;
        this.dialogVisible = true;
      } else if (
        suffix === "AVI" ||
        suffix === "MOV" ||
        suffix === "MP4" ||
        suffix === "3GP" ||
        suffix === "FLV" ||
        suffix === "RMVB" ||
        suffix === "RM"
      ) {
        this.dialogVideoUrl = filename;
        this.dialogVisible = true;
      } else {
        window.open(filename, "_blank");
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 20 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    handleChange(file, fileList) {
      console.log(fileList.length);
      this.fileStartCount += 1;
    },
    beforeAvatarUpload(file) {
      const isLt100M = file.size / 1024 / 1024 < 100;
      if (!isLt100M) {
        this.$message.error("上传文件大小不能超过100MB!");
      }
      return isLt100M;
    },
    async handleAvatarSuccess1(response, file, fileList) {
      //上传成功的钩子P
      console.log(fileList);
      this.uploadloading = false;
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 1000);
      });
      console.log(this.uploadloading);
      if (this.uploadloading == true) return;
      this.showUploading = false;
      let idx = response.data.name;
      this.form[idx].image = "";
      this.form[idx].video = "";
      this.form[idx].file = "";
      fileList.forEach((item, index) => {
        let suffix;
        let img_name;
        if (!this.form[idx].showImages[index]) {
          this.form[idx].showImages.push(item);
        }
        if (item.ifGetFromBackEnd) {
          suffix = item.name.split(".").pop().toUpperCase();
          img_name = item.name;
        } else {
          suffix = item.response.data.suffix.toUpperCase();
          img_name = item.response.data.img_name;
        }
        if (
          suffix === "JPG" ||
          suffix === "JPEG" ||
          suffix === "PNG" ||
          suffix === "GIF"
        ) {
          this.form[idx].image += img_name + ";";
          this.form[idx].showImages[index].type = "image";
        } else if (
          suffix === "AVI" ||
          suffix === "MOV" ||
          suffix === "MP4" ||
          suffix === "3GP" ||
          suffix === "FLV" ||
          suffix === "RMVB" ||
          suffix === "RM"
        ) {
          this.form[idx].video += img_name + ";";
          this.form[idx].showImages[index].type = "video";
        } else {
          this.form[idx].file += img_name + ";";
          this.form[idx].showImages[index].type = "file";
        }
      });
      if (this.form[idx].image.length > 0) {
        this.form[idx].image = this.form[idx].image.substr(
          0,
          this.form[idx].image.length - 1
        );
      }
      if (this.form[idx].video.length > 0) {
        this.form[idx].video = this.form[idx].video.substr(
          0,
          this.form[idx].video.length - 1
        );
      }
      if (this.form[idx].file.length > 0) {
        this.form[idx].file = this.form[idx].file.substr(
          0,
          this.form[idx].file.length - 1
        );
      }
    },
    handleTreamentSuccess(response, file, fileList) {
      console.log(response.data.name);
      console.log(file);
      console.log(fileList);
       console.log(this.fileList);
      this.fileList.push(response.data.name);
    },
    async handleTreamentSuccess1(response, file, fileList) {
      //上传成功的钩子
      this.uploadloading = false;
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 600);
      });
      if (this.uploadloading == true) return;
      this.showUploading = false;
      console.log(fileList);
      let idx = response.data.name;
      this.form2[idx].image = "";
      this.form2[idx].video = "";
      this.form2[idx].file = "";
      // this.form2[idx].showItems.push(file)
      fileList.forEach((item, index) => {
        let suffix;
        let img_name;
        if (!this.form2[idx].showItems[index]) {
          this.form2[idx].showItems.push(item);
        }
        if (item.ifGetFromBackEnd) {
          suffix = item.name.split(".").pop().toUpperCase();
          img_name = item.name;
        } else {
          suffix = item.response.data.suffix.toUpperCase();
          img_name = item.response.data.img_name;
        }
        // let suffix = item.response.data.suffix.toUpperCase();
        // let img_name = item.response.data.img_name;
        if (
          suffix === "JPG" ||
          suffix === "JPEG" ||
          suffix === "PNG" ||
          suffix === "GIF"
        ) {
          this.form2[idx].image += img_name + ";";
          this.form2[idx].showItems[index].type = "image";
        } else if (
          suffix === "AVI" ||
          suffix === "MOV" ||
          suffix === "MP4" ||
          suffix === "3GP" ||
          suffix === "FLV" ||
          suffix === "RMVB" ||
          suffix === "RM"
        ) {
          this.form2[idx].video += img_name + ";";
          this.form2[idx].showItems[index].type = "video";
        } else {
          this.form2[idx].file += img_name + ";";
          this.form2[idx].showItems[index].type = "file";
        }
      });
      console.log(this.form2[idx]);
      if (this.form2[idx].image.length > 0) {
        this.form2[idx].image = this.form2[idx].image.substr(
          0,
          this.form2[idx].image.length - 1
        );
      }
      if (this.form2[idx].video.length > 0) {
        this.form2[idx].video = this.form2[idx].video.substr(
          0,
          this.form2[idx].video.length - 1
        );
      }
      if (this.form2[idx].file.length > 0) {
        this.form2[idx].file = this.form2[idx].file.substr(
          0,
          this.form2[idx].file.length - 1
        );
      }
    },
    treatmentClose() {
      this.treatment = false;
      this.fileList = [];
    },
    handleEditReport(done) {
      this.form = [
        {
          title: "",
          check_time: "",
          showImages: [],
          image: "",
          video: "",
          file: "",
          id: "",
        },
      ];
      done();
    },
  },
};
</script>
<style>
/* .el-upload-list {
  width: 150px;
} */
.page-memberInfo .upload_btnbox .el-upload-list--picture .el-upload-list__item {
  padding: 10px;
}

.el-form-item {
  margin-bottom: 35px;
}

.upload_btnbox .el-upload--text {
  border: none;
  width: 80px;
  height: 40px;
}

.textwidth .el-textarea__inner {
  width: 390px;
}

.textwidth {
  margin-bottom: 40px;
}

.textwidth .el-textarea {
  width: 400px;
}
</style>
<style scoped>
.file-preview {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  display: flex;
  position: relative;
}

.file-preview .file-pic {
  height: 70px;
  width: 70px;
}

.file-preview .file-type {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.file-preview .file-name {
  margin-left: 8px;
  height: 70px;
  line-height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.removebtn {
  width: 20px;
  height: 20px;
}

.imgsize {
  margin-top: 10px;
  width: 100px;
  height: 100px;
}

.imgflex {
  display: flex;
  flex: 1;
}

.Max,
.Max2 {
  position: absolute;
  text-align: center;
  height: 48px;
  width: 45px;
  z-index: 100;
  color: #409eff;
  font-size: 12px;
}

.Max {
  right: 40px;
}

.Max2 {
  right: 80px;
}

.topBox {
  border-bottom: 1px solid #999;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.leftBox {
  display: flex;
  align-items: flex-end;
}

.number {
  margin-left: 50px;
  font-size: 14px;
  color: #999;
}

.rightBox {
  font-size: 14px;
  color: #999;
}

.mgb20 {
  display: flex;
  flex-direction: column;
}

.bar {
  font-size: 14px;
  line-height: 40px;
  margin-right: 50px;
}

.title {
  margin: 20px 0;
}

.input {
  width: 218px;
}

.titleBox .f14 {
  /* padding-left: 30px; */
  margin-right: 80px;
}

.titleBox {
  position: relative;
  width: 100%;
  padding-right: 30px;
}

.list4 {
  padding-top: 30px;
}

.topBorder {
  border-top: 1px solid #999;
}

.reportInp {
  width: 220px;
}

.center {
  display: flex;
  justify-content: center;
}

.markup {
  margin-bottom: 40px;
}

.markup-title {
  font-size: 19px;
  font-weight: 550;
}

.markup-content {
  margin-top: 10px;
  /* margin-left: 60px; */
  font-size: 14px;
  color: #606266;
}
</style>